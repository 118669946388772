import { template as template_fc72d975778b437d9cabdb6dd542c921 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_fc72d975778b437d9cabdb6dd542c921(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
