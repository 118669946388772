import { template as template_eff8c324002e4ce5a4eaba65eb5abea7 } from "@ember/template-compiler";
const WelcomeHeader = template_eff8c324002e4ce5a4eaba65eb5abea7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
